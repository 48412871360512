import React from 'react';
import styled, { keyframes } from 'styled-components';
import { H2, H3 } from 'components/atoms/Typography';
import ServiceTile from 'components/molecules/ServiceTile/ServiceTile';
import ThemeShape5 from 'images/themeShapes/themeShape_5.svg';
import { colors, breakPoints } from 'utils/variables';
// import { LinkButton } from 'components/atoms/Button/Button';
import { shortServices } from 'templates/ServicesList/ServicesList';

const StyledWrapper = styled.div`
  position: relative;
  padding-top: 0.7rem;

  button {
    position: relative;
    display: block;
    z-index: 10;
    margin: 5.9rem auto 0 auto;
    animation: ${keyframes`
      0%, 5% {
        transform: rotateZ(0);
      }
      1%, 3% {
        transform: rotateZ(-5deg);
      }
      2%, 4% {
        transform: rotateZ(5deg);
      }
    `} 10s linear infinite;
  }
`;

const StyledHeading = styled.div`
  position: relative;
  z-index: 10;

  h3 {
    margin-bottom: 1.7rem;
  }
  h2 {
    margin-bottom: 4.7rem;
  }
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 6rem;

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }

  > div {
    width: 100%;
    padding: 2rem 0;
    margin: 1rem 0;
    min-height: unset;

    @media (min-width: ${breakPoints.desktop}) {
      width: 22rem;
      padding: 3rem 2.5rem;

      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }
    }
  }
`;

const StyledShape = styled.img`
  position: absolute;
  top: -13.5rem;
  left: -51%;
`;

const getTiles = service => <ServiceTile service={service} key={service.title} />;

const ShortServices = () => (
  <StyledWrapper>
    <StyledHeading>
      <H3 color={colors.color3}>Dlaczego warto nas wybrać?</H3>
      <H2>
        Mnogość usług oraz
        <br />
        <b>bogate zaplecze sprzętowe</b>
      </H2>
    </StyledHeading>
    <StyledContent>{shortServices.slice(0, 4).map(getTiles)}</StyledContent>
    {/* <LinkButton to="/uslugi" text="Zobacz wszystkie usługi" /> */}
    <StyledShape src={ThemeShape5} alt="Short services shape" />
  </StyledWrapper>
);

export default ShortServices;
