import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Text, { H5 } from 'components/atoms/Typography';
import { hexToRGBA } from 'utils/lib';
import ThemeShape1 from 'images/themeShapes/themeShape_1.inline.svg';
import { colors } from 'utils/variables';

const StyledWrapper = styled.div`
  background-color: ${colors.white};
  position: relative;
  text-align: center;
  padding: 3rem 2.5rem;
  width: 22rem;
  min-height: 34rem;
  box-shadow: 0.2rem 0.2rem 2rem ${hexToRGBA('#000000', 0.1)};

  h5 {
    margin-bottom: 1rem;
    min-height: 4rem;
  }

  .text {
    margin-top: 2.2rem;
  }

  .shine {
    width: 100%;
    height: 100%;
    z-index: 15;
    position: absolute;
    border-radius: 2rem;
    top: 0;
    left: 0;
    overflow: hidden;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: transparent;
      background-image: linear-gradient(135deg, rgba(255, 255, 255, 0) 30%, rgba(20, 78, 167, 0.1) 50%, rgba(255, 255, 255, 0) 70%);
      top: 0;
      transform: translate(-150%, 0);
      animation: ${keyframes`
      0%, 80% {
        width: 100%;
        transform: translate(-150%, 0);
        opacity: 0;
      }
      90% {
        width: 300%;
        transform: translate(150%, 0);
        opacity: 1;
      }
      100% {
        width: 300%;
        transform: translate(150%, 0);
        opacity: 0;
      }
    `} 10s linear 2s infinite;
    }
  }
`;

const StyledIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10.4rem;
  margin-bottom: 2.2rem;
`;

const ServiceTile = ({ service }) => {
  const { Icon, title, text } = service;

  return (
    <StyledWrapper>
      <StyledIconContainer>
        <Icon />
      </StyledIconContainer>
      <H5 dangerouslySetInnerHTML={{ __html: title }} />
      <ThemeShape1 />
      <Text html={text} />
      <div className="shine" />
    </StyledWrapper>
  );
};

ServiceTile.propTypes = {
  service: PropTypes.shape({
    Icon: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default ServiceTile;
