import Icon0101 from 'images/services/service_0101.inline.svg';
import Icon0102 from 'images/services/service_0102.inline.svg';
import Icon0103 from 'images/services/service_0103.inline.svg';
import Icon0104 from 'images/services/service_0104.inline.svg';
import Icon0105 from 'images/services/service_0105.inline.svg';
import Icon0201 from 'images/services/service_0201.inline.svg';
import Icon0202 from 'images/services/service_0202.inline.svg';
import Icon0203 from 'images/services/service_0203.inline.svg';
import Icon0301 from 'images/services/service_0301.inline.svg';
import Icon0302 from 'images/services/service_0302.inline.svg';
import Icon0303 from 'images/services/service_0303.inline.svg';
import Icon0304 from 'images/services/service_0304.inline.svg';
import Icon0305 from 'images/services/service_0305.inline.svg';
import Icon0306 from 'images/services/service_0306.inline.svg';
import Icon0401 from 'images/services/service_0401.inline.svg';
import Icon0402 from 'images/services/service_0402.inline.svg';
import Icon0403 from 'images/services/service_0403.inline.svg';
import IconOgumienie from 'images/services/service_ogumienie.inline.svg';
import IconMechanika from 'images/services/service_mechanika.inline.svg';
import IconDiagnostyka from 'images/services/service_diagnostyka.inline.svg';
import IconKlimatyzacje from 'images/services/service_klimatyzacje.inline.svg';

const servicesList = {
  mechanika: [
    {
      Icon: Icon0101,
      title: 'Naprawy układów zawieszenia',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0102,
      title: 'Naprawy układów hamulcowych',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0103,
      title: 'Wymiana rozrządu',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0104,
      title: 'Wymiana olejów i filtrów',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0105,
      title: 'Naprawa układów wydechowych',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
  ],
  diagnostyka: [
    {
      Icon: Icon0201,
      title: 'Geometria podwozia',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0202,
      title: 'Diagnostyka komputerowa pojazdów',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0203,
      title: 'Przeglądy samochodów przed zakupem',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
  ],
  ogumienie: [
    {
      Icon: Icon0301,
      title: 'Sprzedaż opon różnych producentów',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0302,
      title: 'Wymiana opon',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0303,
      title: 'Wulkanizacja',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0304,
      title: 'Wyważanie kół stalowych i aluminiowych',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0305,
      title: 'Przechowanie opon i kół',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0306,
      title: 'Obsługa czujników ciśnienia TPMS',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
  ],
  klimatyzacje: [
    {
      Icon: Icon0401,
      title: 'Okresowa kontrola układu klimatyzacji ',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0402,
      title: 'Diagnostyka i naprawa układów klimatyzacji',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
    {
      Icon: Icon0403,
      title: 'Odgrzybianie metodą ozonowania',
      text:
        'W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem. Dodatkowo oferujemy Państwu przechowywanie opon i kół.W naszym serwisie oferujemy sprzedaż oraz wymianę opon z wyważeniem',
    },
  ],
};

const shortServices = [
  {
    Icon: IconOgumienie,
    title: 'Ogumienie',
    text:
      '&bull; Wymiana opon &bull; Wulkanizacja &bull; Sprzedaż opon różnych producentów &bull; Wyważanie kół stalowych i aluminiowych &bull; Przechowanie opon i kół &bull; Obsługa czujników ciśnienia TPMS &bull;',
  },
  {
    Icon: IconMechanika,
    title: 'Mechanika',
    text:
      '&bull; Wymiana olejów i filtrów &bull; Naprawy układów zawieszenia &bull; Naprawy układów hamulcowych &bull; Wymiana rozrządu &bull; Naprawa układów wydechowych &bull;',
  },
  {
    Icon: IconDiagnostyka,
    title: 'Diagnostyka',
    text: '&bull; Diagnostyka komputerowa pojazdów &bull; Przeglądy samochodów przed zakupem &bull; Geometria podwozia &bull;',
  },
  {
    Icon: IconKlimatyzacje,
    title: 'Klimatyzacje',
    text:
      '&bull; Napełnianie układu klimatyzacji &bull; Kontrola układu klimatyzacji &bull; Diagnostyka i naprawa układów klimatyzacji &bull; Odgrzybianie metodą ozonowania &bull;',
  },
];

export { shortServices };
export default servicesList;
